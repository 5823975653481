import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setAccessToken} from "../../../store/reducers/userSlice";
import {Button, Container, Description, Form, Input} from "../SharedStyles";
import {SignUpLink, SignupWrapper} from "./styled";
import ErrorMessage from "../../Shared/ErrorMessage";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const response = await fetch(API_URL + '/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Login failed. Please try again.');
        return;
      }

      dispatch(setAccessToken(data.accessToken));
      navigate('/dashboard');
    } catch (err) {
      setError('Unexpected error. Please try again.');
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Description>Sign into your account: </Description>
        <Input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <ErrorMessage message={error} />}
        <Button type="submit">Login</Button>
        <SignupWrapper>
          <Description>Don't have an account? &nbsp;
            <SignUpLink href={"/signup"}>Signup here</SignUpLink>
          </Description>
        </SignupWrapper>
      </Form>
    </Container>
  );
};

export default Login;