
export const IFE = 'IFE';
export const MFE = 'MFE';
export const MIF = 'MIF';

export function displayReactorType(str) {
  if (!str) return "";
  if (str === IFE) return 'Inertial Fusion Energy (IFE. Lasers)';
  if (str === MFE) return 'Magnetic Fusion Energy (MFE, Tokamak)';
  if (str === MIF) return 'Magneto-Inertial Fusion (MIF, Hybrid)';
  return str;
}

export function firstUpper(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function displayFuelType(str) {
  if (!str) return "";
  if (str === 'DT') return 'DT';
  if (str === 'DHE3') return 'DHe3';
  if (str === 'PB11') return 'Pb11';
  return str;
}

export function toHtmlId(text) {
  return text.toLowerCase().replace(/[^a-z0-9-]/g, '').replace(/\s+/g, '-');
}