import React, {useEffect, useState} from 'react';
import {SliderContainer, LabelWrapper, ValueInput, Slider, SliderWrapper, MinValue, MaxValue, Label} from './styled';
import InfoTooltip from "../InfoTooltip";
import {toHtmlId} from "../../../utils/viewUtils";

const SliderInput = ({ label, min, max, unit, step, setValue, defaultValue, initValue, description }) => {
  const middleValue = (min + max) / 2;
  const [value, internalSetValue] = useState(initValue || defaultValue || middleValue);

  useEffect(() => {
    setValue(value);
  }, [setValue, value]);

  const handleInputChange = (newValue) => {
    internalSetValue(newValue);
  };

  const handleInputBlur = () => {
    const coercedValue = Math.min(Math.max(Number(value), min), max);
    internalSetValue(coercedValue);
    setValue(coercedValue);
  };

  const handleSliderChange = (newValue) => {
    const numericValue = Math.min(Math.max(Number(newValue), min), max);
    internalSetValue(numericValue);
    setValue(numericValue);
  };

  const tooltipId = toHtmlId(label);

  return (
    <SliderContainer>
      <LabelWrapper>
        <Label>
          {label} {unit ? `(${unit})` : ""}
          {description && <InfoTooltip infoText={description} id={tooltipId}/>}
        </Label>
        <ValueInput
          type="number"
          value={value}
          step={step}
          onChange={(e) => handleInputChange(e.target.value)}
          onBlur={handleInputBlur}
        />
      </LabelWrapper>
      <SliderWrapper>
        <MinValue>{min}</MinValue>
        <Slider
          type="range"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => handleSliderChange(e.target.value)}
        />
        <MaxValue>{max}</MaxValue>
      </SliderWrapper>
    </SliderContainer>
  );
};

export default SliderInput;
