import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 600px;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px 20px 20px;

  ${props => props.alignStart && `
    align-items: flex-start;
  `}
`;

export const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 10px;
  width: calc(100% - 22px);
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #333;
  margin-bottom: 0;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;

export const Button = styled.button`
  flex: 1;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    background-color: #0056b3;
  }
`;

export const BackButton = styled(Button)`
  background-color: white;
  color: black;

  &:hover {
    background-color: #ccc;
    color: black;
  }
`;

export const AdvancedSettings = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  margin-top: 10px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: #333;
  display: block;
`;

export const DropdownContainer = styled.div`
  margin-bottom: 10px;
`;

export const Dropdown = styled.select`
  -webkit-appearance: none;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  width: 100%;
  background: white;
`;