import lodash from 'lodash';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {removeNullValues} from "../../utils/utils";

const API_URL = process.env.REACT_APP_API_BASE_URL;
const isOrgSiteEnabled = process.env.REACT_APP_ORG_SITE_ENABLED === 'True';
const BASE_PATH = isOrgSiteEnabled ? 'org/configurations' : 'user/configurations';

const initialState = {
  error: '',
  configurationId: null,
  projectConfigurationId: null,
  projectId: null,
  hasReportTex: false,
  hasReportPdf: false,
  name: null,
  input: {
    project: {
      reactorType: null,
      energyConversion: null,
      fuelType: null,
    },
    basic: {
      fusionPower: null,
      numberModules: null,
      aM: null,
      constructionTime: null,
      plantLifetime: null,
      plantAvailabilityFactor: null,
    },
    power: {
      subsystemAndControlFraction: null,
      cryoVacuumPumping: null,
      neutronEnergyMultiplier: null,
      pumpingPowerCaptureEfficiency: null,
      thermalConversionEfficiency: null,
      primaryCoolantPumpingPowerFraction: null,
      tritiumSystems: null,
      housekeepingPower: null,
      tfCoilCooling: null,
      pfCoilCooling: null,
      powerIntoTfCoils: null,
      powerIntoPfCoils: null,
      inputPowerWallPlugEfficiency: null,
      directEnergyConversionEfficiency: null,
      totalPowerInput: null,
      inputPowerWallPlugEfficiencyImplosion: null,
      inputPowerWallPlugEfficiencyIgnition: null,
      recoveryEfficiency: null,
      compressionSystemEfficiency: null,
      powerIntoCompressionSystem: null,
      recoveredPower: null,
      powerForPlasmaTargetFormation: null,
      targetFormationEfficiency: null,
      powerIntoImplosionLaser: null,
      powerIntoIgnitionLaser: null,
    },
    blanket: {
      firstWallType: "",
      blanketType: "",
      primaryCoolantType: "",
      secondaryCoolantType: "",
      neutronMultiplier: "",
      structure: "",
    },
    materials: {
      ybcoTapeCost: null,
      stainlessSteelCost: null,
      copperCost: null,
      mfrFactor: null,
    }
  }
};

export const fetchConfiguration = createAsyncThunk(
  'configuration/fetchConfiguration',
  async (configurationId, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;

    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}/${configurationId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData.error || 'Configuration not found...');
      }

      return response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const downloadConfigurationPdf = createAsyncThunk(
  'configuration/downloadPdf',
  async (configurationId, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;
    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}/${configurationId}/pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Authorization': `Bearer ${token}`
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData.error || 'Configuration not found...');
      }
      const blob = await response.blob();
      return { blob, filename: `configuration-${configurationId}.pdf` };
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const downloadConfigurationTex = createAsyncThunk(
  'configuration/downloadTex',
  async (configurationId, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;
    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}/${configurationId}/tex`, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/plain',
          'Authorization': `Bearer ${token}`
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData.error || 'Configuration not found...');
      }
      const blob = await response.blob();
      return { blob, filename: `configuration-${configurationId}.tex` };
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const createConfiguration = createAsyncThunk(
  'configuration/createConfiguration',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const token = state.user.accessToken;

    const configurationData = lodash.cloneDeep({ ...state.configuration })
    const configuration = removeNullValues(configurationData, ['name']);

    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(configuration),
      });

      const data = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(data.error || 'Create configuration failed. Please try again.');
      }

      return data.configurationId;
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setProjectId: (state, action) => {
      state.projectId = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setFusionPower: (state, action) => {
      state.input.basic.fusionPower = action.payload;
    },
    setNumberModules: (state, action) => {
      state.input.basic.numberModules = action.payload;
    },
    setAM: (state, action) => {
      state.input.basic.aM = action.payload;
    },
    setConstructionTime: (state, action) => {
      state.input.basic.constructionTime = action.payload;
    },
    setPlantLifetime: (state, action) => {
      state.input.basic.plantLifetime = action.payload;
    },
    setPlantAvailabilityFactor: (state, action) => {
      state.input.basic.plantAvailabilityFactor = action.payload;
    },
    setSubsystemAndControlFraction: (state, action) => {
      state.input.power.subsystemAndControlFraction = action.payload;
    },
    setCryoVacuumPumping: (state, action) => {
      state.input.power.cryoVacuumPumping = action.payload;
    },
    setNeutronEnergyMultiplier: (state, action) => {
      state.input.power.neutronEnergyMultiplier = action.payload;
    },
    setPumpingPowerCaptureEfficiency: (state, action) => {
      state.input.power.pumpingPowerCaptureEfficiency = action.payload;
    },
    setThermalConversionEfficiency: (state, action) => {
      state.input.power.thermalConversionEfficiency = action.payload;
    },
    setPrimaryCoolantPumpingPowerFraction: (state, action) => {
      state.input.power.primaryCoolantPumpingPowerFraction = action.payload;
    },
    setTritiumSystems: (state, action) => {
      state.input.power.tritiumSystems = action.payload;
    },
    setHousekeepingPower: (state, action) => {
      state.input.power.housekeepingPower = action.payload;
    },
    setTfCoilCooling: (state, action) => {
      state.input.power.tfCoilCooling = action.payload;
    },
    setPfCoilCooling: (state, action) => {
      state.input.power.pfCoilCooling = action.payload;
    },
    setPowerIntoTfCoils: (state, action) => {
      state.input.power.powerIntoTfCoils = action.payload;
    },
    setPowerIntoPfCoils: (state, action) => {
      state.input.power.powerIntoPfCoils = action.payload;
    },
    setInputPowerWallPlugEfficiency: (state, action) => {
      state.input.power.inputPowerWallPlugEfficiency = action.payload;
    },
    setDirectEnergyConversionEfficiency: (state, action) => {
      state.input.power.directEnergyConversionEfficiency = action.payload;
    },
    setTotalPowerInput: (state, action) => {
      state.input.power.totalPowerInput = action.payload;
    },
    setInputPowerWallPlugEfficiencyImplosion: (state, action) => {
      state.input.power.inputPowerWallPlugEfficiencyImplosion = action.payload;
    },
    setInputPowerWallPlugEfficiencyIgnition: (state, action) => {
      state.input.power.inputPowerWallPlugEfficiencyIgnition = action.payload;
    },
    setRecoveryEfficiency: (state, action) => {
      state.input.power.recoveryEfficiency = action.payload;
    },
    setCompressionSystemEfficiency: (state, action) => {
      state.input.power.compressionSystemEfficiency = action.payload;
    },
    setPowerIntoCompressionSystem: (state, action) => {
      state.input.power.powerIntoCompressionSystem = action.payload;
    },
    setRecoveredPower: (state, action) => {
      state.input.power.recoveredPower = action.payload;
    },
    setPowerForPlasmaTargetFormation: (state, action) => {
      state.input.power.powerForPlasmaTargetFormation = action.payload;
    },
    setTargetFormationEfficiency: (state, action) => {
      state.input.power.targetFormationEfficiency = action.payload;
    },
    setPowerIntoImplosionLaser: (state, action) => {
      state.input.power.powerIntoImplosionLaser = action.payload;
    },
    setPowerIntoIgnitionLaser: (state, action) => {
      state.input.power.powerIntoIgnitionLaser = action.payload;
    },
    setFirstWallType: (state, action) => {
      state.input.blanket.firstWallType = action.payload;
    },
    setPrimaryCoolantType: (state, action) => {
      state.input.blanket.primaryCoolantType = action.payload;
    },
    setSecondaryCoolantType: (state, action) => {
      state.input.blanket.secondaryCoolantType = action.payload;
    },
    setBlanketType: (state, action) => {
      state.input.blanket.blanketType = action.payload;
    },
    setNeutronMultiplier: (state, action) => {
      state.input.blanket.neutronMultiplier = action.payload;
    },
    setStructure: (state, action) => {
      state.input.blanket.structure = action.payload;
    },
    setYbcoTapeCost: (state, action) => {
      state.input.materials.ybcoTapeCost = action.payload;
    },
    setStainlessSteelCost: (state, action) => {
      state.input.materials.stainlessSteelCost = action.payload;
    },
    setCopperCost: (state, action) => {
      state.input.materials.copperCost = action.payload;
    },
    setMfrFactor: (state, action) => {
      state.input.materials.mfrFactor = action.payload;
    },
    setCurrentConfiguration: (state, action) => {
      state = {...action.payload}
    },
    clearConfiguration: state => {
      return initialState;
    },
  },
  extraReducers: {
    [createConfiguration.fulfilled]: (state, action) => {
      state.createdConfigurationId = action.payload;
      configurationSlice.caseReducers.clearConfiguration(state);
    },
    [createConfiguration.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchConfiguration.fulfilled]: (state, action) => {
      state.currentConfiguration = action.payload;
    },
    [fetchConfiguration.rejected]: (state, action) => {
      state.error = action.error.message;
    }
  },
});

export const {
  setProjectId,
  // basic
  setName,
  setFusionPower,
  setNumberModules,
  setAM,
  setConstructionTime,
  setPlantLifetime,
  setPlantAvailabilityFactor,
  // power
  setSubsystemAndControlFraction,
  setCryoVacuumPumping,
  setNeutronEnergyMultiplier,
  setPumpingPowerCaptureEfficiency,
  setThermalConversionEfficiency,
  setPrimaryCoolantPumpingPowerFraction,
  setTritiumSystems,
  setHousekeepingPower,
  setTfCoilCooling,
  setPfCoilCooling,
  setPowerIntoTfCoils,
  setPowerIntoPfCoils,
  setInputPowerWallPlugEfficiency,
  setDirectEnergyConversionEfficiency,
  setTotalPowerInput,
  setInputPowerWallPlugEfficiencyImplosion,
  setInputPowerWallPlugEfficiencyIgnition,
  setRecoveryEfficiency,
  setCompressionSystemEfficiency,
  setPowerIntoCompressionSystem,
  setRecoveredPower,
  setPowerForPlasmaTargetFormation,
  setTargetFormationEfficiency,
  setPowerIntoImplosionLaser,
  setPowerIntoIgnitionLaser,
  // blanket
  setFirstWallType,
  setBlanketType,
  setPrimaryCoolantType,
  setSecondaryCoolantType,
  setNeutronMultiplier,
  setStructure,
  // materials
  setYbcoTapeCost,
  setStainlessSteelCost,
  setCopperCost,
  setMfrFactor,
  // functions
  setCurrentConfiguration,
  clearConfiguration,
} = configurationSlice.actions;

export default configurationSlice.reducer;
