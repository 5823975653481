import {useEffect} from "react";
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Container, CreateButton, ProjectBox, Title} from "./styled";
import {fetchProjects} from "../../store/reducers/projectSlice";

const Dashboard = () => {
  const projects = useSelector(state => state.project.projects);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const navigateToReport = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const navigateToCreate = () => {
    navigate('/projects/create');
  };

  return (
    <>
      <Title>
        Projects
      </Title>
      <Container>
        {projects.map(project => (
          <ProjectBox key={project.projectId} onClick={() => navigateToReport(project.projectId)}>
            {project.name}
          </ProjectBox>
        ))}
        <CreateButton onClick={navigateToCreate}>Create a new Project</CreateButton>
      </Container>
    </>
  );
};

export default Dashboard;