import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setEmail, setDomain} from '../../../store/reducers/userSlice';
import {Button, Container, Description, Form, Input} from "../SharedStyles";
import ErrorMessage from "../../Shared/ErrorMessage";

const Signup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmailInput] = useState('');
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const isOrgSiteEnabled = process.env.REACT_APP_ORG_SITE_ENABLED === 'True';

  const EMAIL_VALIDATION_STATUS = {
    PERSONAL: 'personal',
    ORG_EXISTS: 'org_exists',
    VALID_NEW: 'valid_new',
  };

  const validateEmail = async (email) => {
    try {
      const response = await fetch(API_URL + `/email/validate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', },
        body: JSON.stringify({ email })
      });
      const data = await response.json();

      if (!response.ok) {
        setError(data.error || `Error: ${response.status}. Please try again`);
        return;
      }

      return data
    } catch (err) {
      setError('Unexpected error. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const result = await validateEmail(email);

    if (result && result.status !== EMAIL_VALIDATION_STATUS.PERSONAL) {
      const emailDomain = email.substring(email.lastIndexOf("@") + 1);
      dispatch(setEmail(email));
      dispatch(setDomain(emailDomain));
    }

    if (result) {
      switch(result.status) {
        case EMAIL_VALIDATION_STATUS.PERSONAL:
          setError('Please use your organization email address, not a personal.');
          break;
        case EMAIL_VALIDATION_STATUS.ORG_EXISTS:
          navigate('/login/request');
          break;
        case EMAIL_VALIDATION_STATUS.VALID_NEW:
          if (isOrgSiteEnabled) {
            navigate('/signup/org');
          } else {
            navigate('/signup/user')
          }
          break;
        default:
          setError('Unexpected response from server.');
      }
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Description>Please enter your institution or company email: </Description>
        <ErrorMessage message={error} />
        <Input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmailInput(e.target.value)}
        />
        <Button type="submit">Signup</Button>
      </Form>
    </Container>
  );
};

export default Signup;