import {CenteredContent, Container, Description, Title} from "../SharedStyles";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
  clearConfiguration, downloadConfigurationPdf, downloadConfigurationTex,
  fetchConfiguration,
} from "../../../store/reducers/configurationSlice";
import {Button, Loading, NotFound} from "../../Projects/ProjectDetails/styled";
import ErrorMessage from "../../Shared/ErrorMessage";
import {fetchProject} from "../../../store/reducers/projectSlice";
import {ProjectLink} from "./styled";
import JsonView from "../../Shared/JsonView";


const ConfigurationDetails = () => {
  const { configurationId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const configuration = useSelector(state => state.configuration.currentConfiguration);
  const project = useSelector(state => state.project.currentProject);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const onErrorClick = () => {
    dispatch(clearConfiguration())
    navigate("/configurations/basic")
  }

  function onDownloadReportTex() {
    dispatch(downloadConfigurationTex(configurationId))
      .unwrap()
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error('Download failed', error));
  }

  function onDownloadReportPdf() {
    dispatch(downloadConfigurationPdf(configurationId))
      .unwrap()
      .then(({ blob, filename }) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error('Download failed', error));
  }

  useEffect(() => {
    dispatch(fetchConfiguration(configurationId))
      .unwrap()
      .then(data => {
        dispatch(fetchProject(data.projectId));
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error);
      })
      .finally(() => setLoading(false));
  }, [configurationId, dispatch]);

  if (loading) return (
    <Loading>Loading...</Loading>
  );

  if (error) return (
    <NotFound>
      {error && <ErrorMessage message={error} />}
      <Button onClick={onErrorClick}>Create new configuration</Button>
    </NotFound>
  );

  return (
    <Container alignStart>
      <CenteredContent>
        <Title>Configuration Details #{configuration.projectConfigurationId}</Title>
      </CenteredContent>
      <Description>Name: {configuration.name}</Description>
      <Description>Project Configuration #{configuration.projectConfigurationId}</Description>
      <Description>Organization Configuration #{configuration.configurationId}</Description>
      <Description>Inputs:</Description>
      <JsonView jsonData={configuration.input}/>
      {configuration.hasReportTex && <Button onClick={onDownloadReportTex}>Download Report TEX</Button>}
      {configuration.hasReportPdf && <Button onClick={onDownloadReportPdf}>Download Report PDF</Button>}
      <ProjectLink href={"/projects/" + project?.projectId}>{"<<"} Back to project</ProjectLink>
    </Container>
  )
};

export default ConfigurationDetails;