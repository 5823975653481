import React from 'react';
import NavBar from "../NavBar";
import OrgBar from "../OrgBar";
import {useSelector} from "react-redux";

const Layout = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  return (
    <>
      <NavBar />
      {isLoggedIn && <OrgBar />}
      <main>{children}</main>
    </>
  );
};

export default Layout;