import styled, {css} from 'styled-components';

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #fff;
`;

export const CatfLogo = styled.img`
    width: 200px;
    height: auto;
    margin-left: 30px;
`;

export const WsltdLogo = styled.img`
    width: 160px;
    height: auto;
    margin-right: 20px;
    margin-left: 30px;
`;

export const LeftSection = styled.div`
    display: flex;
    align-items: center;
`;

export const NavSection = styled.div`
    display: flex;
    align-items: center;
`;

export const Nav = styled.nav`
  display: flex;
  gap: 1rem;
`;

const baseNavItemStyle = css`
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 20px;
  &:hover {
    color: #777;
  }
`;

export const NavItem = styled.a`
  ${baseNavItemStyle}
`;

export const NavButton = styled.button`
  ${baseNavItemStyle};
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
`;

export const CatfItem = styled.a`
  ${baseNavItemStyle};
  color: #0045b5;
  margin: -10px 20px;
  &:hover {
    color: #00b1e0;
  }
`;
