import styled from "styled-components";

export const Title = styled.h2`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 20px;
  padding: 10px 30px;
  justify-content: center;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  padding: 10px;
  aspect-ratio: 16 / 9;
  font-size: 20px;
  cursor: pointer;
  height: 120px;
`;

export const ProjectBox = styled(FlexBox)`
  background-color: white;
  color: black;
`;

export const CreateButton = styled(FlexBox)`
  background-color: #009688;
  color: white;
`;