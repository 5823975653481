import React from 'react';
import ReactJson from '@microlink/react-json-view'
import styled from "styled-components";

const JsonWrapper = styled.div`
  margin-top: 5px;
  overflow-y: auto;
`;

const JsonView = ({ jsonData }) => {
  return (
    <JsonWrapper>
      <ReactJson src={jsonData} theme="rjv-default" collapsed={1} name={false}/>
    </JsonWrapper>
  );
};

export default JsonView;