import React from 'react';
import styled from 'styled-components';
import { Tooltip } from "react-tooltip";
import { BsInfoCircle } from "react-icons/bs";

const InfoIcon = styled.span`
  margin-left: 5px;
`;

const InfoTooltip = ({ infoText, id }) => {
  if (!infoText) {
    return null;
  }

  return (
    <>
      <InfoIcon
        data-tooltip-id={id}
        data-tooltip-content={infoText}
        data-tooltip-place="right">
        <BsInfoCircle />
      </InfoIcon>
      <Tooltip id={id} />
    </>
  );
};

export default InfoTooltip;