import {useDispatch, useSelector} from "react-redux";
import {
  setSubsystemAndControlFraction,
  setCryoVacuumPumping,
  setNeutronEnergyMultiplier,
  setPumpingPowerCaptureEfficiency,
  setThermalConversionEfficiency,
  setPrimaryCoolantPumpingPowerFraction,
  setTritiumSystems,
  setHousekeepingPower,
  setTfCoilCooling,
  setPfCoilCooling,
  setPowerIntoTfCoils,
  setPowerIntoPfCoils,
  setInputPowerWallPlugEfficiency,
  setDirectEnergyConversionEfficiency,
  setTotalPowerInput,
  setInputPowerWallPlugEfficiencyImplosion,
  setInputPowerWallPlugEfficiencyIgnition,
  setRecoveryEfficiency,
  setCompressionSystemEfficiency,
  setPowerIntoCompressionSystem,
  setRecoveredPower,
  setPowerForPlasmaTargetFormation,
  setTargetFormationEfficiency,
  setPowerIntoImplosionLaser,
  setPowerIntoIgnitionLaser,
} from "../../../store/reducers/configurationSlice";
import {BackButton, Button, ButtonsWrapper, Container, Form, Title} from "../SharedStyles";
import SliderInput from "../../Shared/SliderInput";
import {useNavigate} from "react-router-dom";
import {IFE, MFE, MIF} from "../../../utils/viewUtils";


const PowerConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const reactorType = useSelector(state => state.project.currentProject.reactorType);

  const initSubsystemAndControlFraction = useSelector(state => state.configuration.input.power.subsystemAndControlFraction);
  const initCryoVacuumPumping = useSelector(state => state.configuration.input.power.cryoVacuumPumping);
  const initNeutronEnergyMultiplier = useSelector(state => state.configuration.input.power.neutronEnergyMultiplier);
  const initPumpingPowerCaptureEfficiency = useSelector(state => state.configuration.input.power.pumpingPowerCaptureEfficiency);
  const initThermalConversionEfficiency = useSelector(state => state.configuration.input.power.thermalConversionEfficiency);
  const initPrimaryCoolantPumpingPowerFraction = useSelector(state => state.configuration.input.power.primaryCoolantPumpingPowerFraction);
  const initTritiumSystems = useSelector(state => state.configuration.input.power.tritiumSystems);
  const initHousekeepingPower = useSelector(state => state.configuration.input.power.housekeepingPower);
  const initTfCoilCooling = useSelector(state => state.configuration.input.power.tfCoilCooling);
  const initPfCoilCooling = useSelector(state => state.configuration.input.power.pfCoilCooling);
  const initPowerIntoTfCoils = useSelector(state => state.configuration.input.power.powerIntoTfCoils);
  const initPowerIntoPfCoils = useSelector(state => state.configuration.input.power.powerIntoPfCoils);
  const initInputPowerWallPlugEfficiency = useSelector(state => state.configuration.input.power.inputPowerWallPlugEfficiency);
  const initDirectEnergyConversionEfficiency = useSelector(state => state.configuration.input.power.directEnergyConversionEfficiency);
  const initTotalPowerInput = useSelector(state => state.configuration.input.power.totalPowerInput);
  const initInputPowerWallPlugEfficiencyImplosion = useSelector(state => state.configuration.input.power.inputPowerWallPlugEfficiencyImplosion);
  const initInputPowerWallPlugEfficiencyIgnition = useSelector(state => state.configuration.input.power.inputPowerWallPlugEfficiencyIgnition);
  const initRecoveryEfficiency = useSelector(state => state.configuration.input.power.recoveryEfficiency);
  const initCompressionSystemEfficiency = useSelector(state => state.configuration.input.power.compressionSystemEfficiency);
  const initPowerIntoCompressionSystem = useSelector(state => state.configuration.input.power.powerIntoCompressionSystem);
  const initRecoveredPower = useSelector(state => state.configuration.input.power.recoveredPower);
  const initPowerPlasmaTargetFormation = useSelector(state => state.configuration.input.power.powerForPlasmaTargetFormation);
  const initTargetFormationEfficiency = useSelector(state => state.configuration.input.power.targetFormationEfficiency);
  const initPowerIntoImplosionLaser = useSelector(state => state.configuration.input.power.powerIntoImplosionLaser);
  const initPowerIntoIgnitionLaser = useSelector(state => state.configuration.input.power.powerIntoIgnitionLaser);

  const handleBack = () => {
    navigate("/configurations/basic")
  }

  const handleNext = () => {
    navigate("/configurations/blanket")
  }

  return (
    <Container>
      <Title>Power Table Configuration</Title>
      <Form>
        {reactorType === IFE &&
          <>
            <SliderInput
              label="Power into implosion laser"
              unit="MW"
              defaultValue={14}
              min={0}
              max={30}
              step={0.1}
              setValue={(value) => dispatch(setPowerIntoImplosionLaser(value))}
              initValue={initPowerIntoImplosionLaser}
            />
            <SliderInput
              label="Input Power Wall Plug Efficiency (Implosion)"
              description="Efficiency of the compression system - specifically for the implosion lasers."
              defaultValue={0.18}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setInputPowerWallPlugEfficiencyImplosion(value))}
              initValue={initInputPowerWallPlugEfficiencyImplosion}
            />
            <SliderInput
              label="Power into ignition laser"
              unit="MW"
              defaultValue={11.2}
              min={0}
              max={30}
              step={0.1}
              setValue={(value) => dispatch(setPowerIntoIgnitionLaser(value))}
              initValue={initPowerIntoIgnitionLaser}
            />
            <SliderInput
              label="Input Power Wall Plug Efficiency (Ignition)"
              description="Efficiency of the compression system - specifically for the ignition lasers."
              defaultValue={0.82}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setInputPowerWallPlugEfficiencyIgnition(value))}
              initValue={initInputPowerWallPlugEfficiencyIgnition}
            />
          </>
        }

        <SliderInput
          label="Subsystem and Control Fraction"
          description="The fraction of the thermal-electric power generated that is required for the subsystem and control."
          defaultValue={0.03}
          min={0}
          max={1}
          step={0.01}
          setValue={(value) => dispatch(setSubsystemAndControlFraction(value))}
          initValue={initSubsystemAndControlFraction}
        />
        <SliderInput
          label="Cryo Vacuum Pumping"
          description="The power required for cryo vacuum pumping."
          unit="MW"
          defaultValue={0.5}
          min={0}
          max={3}
          step={0.01}
          setValue={(value) => dispatch(setCryoVacuumPumping(value))}
          initValue={initCryoVacuumPumping}
        />
        <SliderInput
          label="Neutron energy multiplier"
          description="In the blanket, when a neutron collides with a nucleus, this is the factor for how many neutrons are emitted."
          defaultValue={1.10}
          min={0}
          max={3}
          step={0.01}
          setValue={(value) => dispatch(setNeutronEnergyMultiplier(value))}
          initValue={initNeutronEnergyMultiplier}
        />
        <SliderInput
          label="Pumping Power Capture Efficiency"
          description="The fraction of power that is captured from confinement."
          defaultValue={0.5}
          min={0}
          max={1}
          step={0.01}
          setValue={(value) => dispatch(setPumpingPowerCaptureEfficiency(value))}
          initValue={initPumpingPowerCaptureEfficiency}
        />
        <SliderInput
          label="Thermal Conversion Efficiency"
          description="How much of the generated power from the fusion reaction is generated as thermal energy."
          defaultValue={0.46}
          min={0}
          max={1}
          step={0.01}
          setValue={(value) => dispatch(setThermalConversionEfficiency(value))}
          initValue={initThermalConversionEfficiency}
        />
        <SliderInput
          label="Primary Coolant Pumping Power Fraction"
          description="The fraction of the thermal-electric power generated that is required for the cooling systems."
          defaultValue={0.06}
          min={0}
          max={1}
          step={0.01}
          setValue={(value) => dispatch(setPrimaryCoolantPumpingPowerFraction(value))}
          initValue={initPrimaryCoolantPumpingPowerFraction}
        />
        <SliderInput
          label="Tritium Systems"
          description="The power required for the tritium systems."
          unit="MW"
          defaultValue={10}
          min={0}
          max={20}
          step={0.1}
          setValue={(value) => dispatch(setTritiumSystems(value))}
          initValue={initTritiumSystems}
        />
        <SliderInput
          label="Housekeeping power"
          description="The power required for housekeeping."
          unit="MW"
          defaultValue={4}
          min={0}
          max={20}
          step={0.1}
          setValue={(value) => dispatch(setHousekeepingPower(value))}
          initValue={initHousekeepingPower}
        />

        {(reactorType === MIF || reactorType === MFE) &&
          <>
            <SliderInput
              label="TF Coil Cooling"
              description="The power required for TF coil cooling."
              unit="MW"
              defaultValue={12.7}
              min={0}
              max={20}
              step={0.1}
              setValue={(value) => dispatch(setTfCoilCooling(value))}
              initValue={initTfCoilCooling}
            />
            <SliderInput
              label="PF Coil Cooling"
              description="The power required for PF coil cooling."
              unit="MW"
              defaultValue={1}
              min={0}
              max={20}
              step={0.1}
              setValue={(value) => dispatch(setPfCoilCooling(value))}
              initValue={initPfCoilCooling}
            />
            <SliderInput
              label="Power into TF Coils"
              description="Power into the toroidal field coils (CS)"
              unit="MW"
              defaultValue={1}
              min={0}
              max={20}
              step={0.1}
              setValue={(value) => dispatch(setPowerIntoTfCoils(value))}
              initValue={initPowerIntoTfCoils}
            />
            <SliderInput
              label="Power into PF Coils"
              description="Power into the poloidal (equilibrium) field coils (mirror)"
              unit="MW"
              defaultValue={6}
              min={0}
              max={20}
              step={1}
              setValue={(value) => dispatch(setPowerIntoPfCoils(value))}
              initValue={initPowerIntoPfCoils}
            />
            <SliderInput
              label="Input Power Wall Plug Efficiency"
              description="Efficiency of the compression system - 10MW to magnet, what is the fraction of that power that goes to compression."
              defaultValue={0.5}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setInputPowerWallPlugEfficiency(value))}
              initValue={initInputPowerWallPlugEfficiency}
            />
            <SliderInput
              label="Direct energy conversion efficiency"
              description="Fraction of the electric energy that is extracted by the direct energy converter."
              defaultValue={0.85}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setDirectEnergyConversionEfficiency(value))}
              initValue={initDirectEnergyConversionEfficiency}
            />
            <SliderInput
              label="Total power input"
              description="Sum of the input power."
              defaultValue={50}
              min={0}
              max={100}
              step={0.1}
              setValue={(value) => dispatch(setTotalPowerInput(value))}
              initValue={initTotalPowerInput}
            />
          </>
        }

        {reactorType === MIF &&
          <>
            <SliderInput
              label="Recovery Efficiency"
              defaultValue={0.5}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setRecoveryEfficiency(value))}
              initValue={initRecoveryEfficiency}
            />
            <SliderInput
              label="Compression System Efficiency"
              defaultValue={0.5}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setCompressionSystemEfficiency(value))}
              initValue={initCompressionSystemEfficiency}
            />
            <SliderInput
              label="Power Into Compression System"
              unit="MW"
              defaultValue={10}
              min={0}
              max={20}
              step={0.1}
              setValue={(value) => dispatch(setPowerIntoCompressionSystem(value))}
              initValue={initPowerIntoCompressionSystem}
            />
            <SliderInput
              label="Recovered Power"
              unit="MW"
              defaultValue={10}
              min={0}
              max={20}
              step={0.1}
              setValue={(value) => dispatch(setRecoveredPower(value))}
              initValue={initRecoveredPower}
            />
            <SliderInput
              label="Power for Plasma Target Formation"
              unit="MW"
              defaultValue={10}
              min={0}
              max={20}
              step={0.1}
              setValue={(value) => dispatch(setPowerForPlasmaTargetFormation(value))}
              initValue={initPowerPlasmaTargetFormation}
            />
            <SliderInput
              label="Target Formation Efficiency"
              defaultValue={0.5}
              min={0}
              max={1}
              step={0.01}
              setValue={(value) => dispatch(setTargetFormationEfficiency(value))}
              initValue={initTargetFormationEfficiency}
            />
          </>
        }

        <ButtonsWrapper>
          <BackButton type="button" onClick={handleBack}>Back</BackButton>
          <Button type="button" onClick={handleNext}>Next</Button>
        </ButtonsWrapper>
      </Form>
    </Container>
  )
};

export default PowerConfiguration;