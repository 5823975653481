import styled from "styled-components";

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #eee;
`;

export const Nav = styled.nav`
  display: flex;
  gap: 1rem;
  background-color: #eee;
`;

export const Title = styled.span`
`;

export const ProjectInfo = styled.span`
`;

export const ProjectLink = styled.a`
  color: black;
  text-decoration: underline;

  &:visited {
    color: black;
  }

  &:hover {
    color: #777;
  }
`;

