import { configureStore } from '@reduxjs/toolkit';
import appReducer from './reducers/appSlice';
import configurationReducer from './reducers/configurationSlice';
import projectReducer from './reducers/projectSlice';
import userReducer from './reducers/userSlice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    configuration: configurationReducer,
    project: projectReducer,
    user: userReducer,
  },
});

export default store;