import {Button, Container, Description, Form} from "../SharedStyles";
import {Highlight} from "../CreateOrg/styled";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";


const RequestLogin = () => {
  const navigate = useNavigate();
  const orgDomain = useSelector((state) => state.user.domain);

  const handleRequest = (e) => {
    e.preventDefault();
    //TODO send login request to backend
    navigate('/login/requested')
  };

  return (
    <Container>
      <Form onSubmit={handleRequest}>
        <Description>You're organization with <Highlight>@{orgDomain}</Highlight> domain already exists.</Description>
        <Description>Would you like to request access from the admin?</Description>
        <Button type="submit">Request Login</Button>
      </Form>
    </Container>
  );
};

export default RequestLogin;