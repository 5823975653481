import React from 'react';
import {Container, Slogan, ActionButton, Wrapper} from './styled';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Hero = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const handleAction = () => {
    isLoggedIn ? navigate('/dashboard') : navigate('/signup');
  }

  return (
    <Container>
      <Wrapper>
        <Slogan>Accelerating fusion power plant design with costing analysis tools</Slogan>
        <ActionButton onClick={handleAction}>
          {isLoggedIn ? 'Go to Dashboard' : 'Sign Up Now'}
        </ActionButton>
      </Wrapper>
    </Container>
  );
}

export default Hero;
