import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {createProject} from "../../../store/reducers/projectSlice";
import {Button, Container, ValidationError, Title, Form, Input, Label, DropdownContainer, Dropdown} from "./styled";
import ErrorMessage from "../../Shared/ErrorMessage";

const CreateProject = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [validationError, setValidationError] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [reactorType, setReactorType] = useState('');
  const [energyConversion, setEnergyConversion] = useState('');
  const [fuelType, setFuelType] = useState('');

  function empty(value) {
    return value === '';
  }

  const isFormValid = () => {
    return name !== '' &&
      reactorType !== '' &&
      energyConversion !== '' &&
      fuelType !== '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      setValidationError(true);
      return;
    }
    setError('');
    setValidationError(false);

    const projectData = {
      name,
      reactorType,
      energyConversion,
      fuelType
    };

    try {
      const resultAction = await dispatch(createProject(projectData)).unwrap();
      navigate(`/projects/${resultAction.projectId}`);
    } catch (rejectedValueOrSerializedError) {
      setError(rejectedValueOrSerializedError);
    }
  };

  return (
    <Container>
      <Title>New Project</Title>
      <Form onSubmit={handleSubmit}>
        <Label>Project Name:</Label>
        { empty(name) && validationError && <ValidationError>Please enter a project name.</ValidationError> }
        <Input
          type="text"
          placeholder="Enter project name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <DropdownContainer>
          <Label htmlFor="reactorType">Reactor Type:</Label>
          { empty(reactorType) && validationError && <ValidationError>Please select a reactor type.</ValidationError> }
          <Dropdown
            id="reactorType"
            value={reactorType}
            onChange={(e) => setReactorType(e.target.value)}
          >
            <option value="">Select a reactor type</option>
            <option value="MFE">Magnetic Fusion Energy (MFE, Tokamak)</option>
            <option value="IFE">Inertial Fusion Energy (IFE. Lasers)</option>
            {/* Commented out until implementation is complete */}
            {/*<option value="MIF">Magneto-Inertial Fusion (MIF, Hybrid)</option>*/}
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <Label htmlFor="energyConversion">Energy Conversion:</Label>
          { empty(reactorType) && validationError && <ValidationError>Please select an energy conversion.</ValidationError> }
          <Dropdown
            id="energyConversion"
            value={energyConversion}
            onChange={(e) => setEnergyConversion(e.target.value)}
          >
            <option value="">Select energy conversion</option>
            <option value="DIRECT">Direct</option>
            <option value="TURBINE">Turbine</option>
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <Label htmlFor="fuelType">Fuel Type:</Label>
          { empty(fuelType) && validationError && <ValidationError>Please select a fuel type.</ValidationError> }
          <Dropdown
            id="fuelType"
            value={fuelType}
            onChange={(e) => setFuelType(e.target.value)}
          >
            <option value="">Select fuel type</option>
            <option value="DT">DT</option>
            <option value="PB11">Pb11</option>
            {/*<option value="DHE3">DHe3</option>*/}
          </Dropdown>
        </DropdownContainer>
        <Button type="submit">Create Project</Button>
        {error && <ErrorMessage message={error} />}
      </Form>
    </Container>
  );
};

export default CreateProject;