import React from 'react';
import styled from 'styled-components';
import { FaPlus, FaMinus } from "react-icons/fa6";


const AdvancedButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #eee;
  color: black;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #ccc;
  }
  margin: 10px 0 10px 0;
`;

const AdvancedSettingsButton = ({ showAdvanced, onToggle }) => {
  return (
    <AdvancedButton type="button" onClick={onToggle}>
      {showAdvanced ? (
        <>
          <FaMinus /> Hide Advanced Variables
        </>
      ) : (
        <>
          <FaPlus /> Show Advanced Variables
        </>
      )}
    </AdvancedButton>
  );
};

export default AdvancedSettingsButton;
