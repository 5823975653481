import {Navigate, Route} from "react-router-dom";

export function authRoute(path, element, isLoggedIn) {
  return (
    <Route
      key={path}
      path={path}
      element={isLoggedIn ? <Navigate to="/dashboard" replace/> : element}
    />
  );
}

export function protectedRoute(path, element, isLoggedIn) {
  if (!isLoggedIn) {
    return <Route key={path} path={path} element={<Navigate to="/login" replace/>}/>;
  }
  return <Route key={path} path={path} element={element}/>;
}