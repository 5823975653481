import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setOrgName} from '../../../store/reducers/userSlice';
import {Button, Container, Description, Form, Input} from "../SharedStyles";
import {Highlight} from "./styled";

const CreateOrg = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orgDomain = useSelector((state) => state.user.domain);
  const [orgName, setOrgNameInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(setOrgName(orgName));
    navigate('/signup/user')
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Description>You'll need to create an organization to use the platform.</Description>
        <Description>Organization Domain: <Highlight>@{orgDomain}</Highlight></Description>
        <Description>Please enter an organization name: </Description>
        <Input
          type="text"
          placeholder="Organization Name"
          value={orgName}
          onChange={(e) => setOrgNameInput(e.target.value)}
        />
        <Button type="submit">Create</Button>
      </Form>
    </Container>
  );
};

export default CreateOrg;