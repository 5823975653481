import {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {checkLoginStatus, getUserState} from "./store/reducers/userSlice";
import {appInitialized} from "./store/reducers/appSlice";
import {authRoute, protectedRoute} from "./utils/routeUtils";
import Landing from "./components/Landing";
import Signup from "./components/AuthFlow/Signup";
import CreateOrg from "./components/AuthFlow/CreateOrg";
import CreateUser from "./components/AuthFlow/CreateUser";
import Login from "./components/AuthFlow/Login";
import RequestLogin from "./components/AuthFlow/RequestLogin";
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import LoginRequested from "./components/AuthFlow/LoginRequested";
import CreateProject from "./components/Projects/CreateProject";
import ProjectDetails from "./components/Projects/ProjectDetails";
import BasicConfiguration from "./components/Configuration/BasicConfiguration";
import PowerConfiguration from "./components/Configuration/PowerConfiguration";
import MaterialsConfiguration from "./components/Configuration/MaterialsConfiguration";
import BlanketConfiguration from "./components/Configuration/BlanketConfiguration";
import ConfigurationDetails from "./components/Configuration/ConfigurationDetails";

function AppContent() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkLoginStatus());
    dispatch(getUserState());
    dispatch(appInitialized());
  }, [dispatch]);

  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  const isAppLoading = useSelector(state => state.app.loading);

  return (
    <Layout>
      {isAppLoading && <div>Loading...</div>}
      {!isAppLoading && (
        <Routes>
          <Route path="/" element={<Landing />} />
          {authRoute("/signup", <Signup/>, isLoggedIn)}
          {authRoute("/signup/org", <CreateOrg/>, isLoggedIn)}
          {authRoute("/signup/user", <CreateUser/>, isLoggedIn)}
          {authRoute("/login", <Login/>, isLoggedIn)}
          {authRoute("/login/request", <RequestLogin/>, isLoggedIn)}
          {authRoute("/login/requested", <LoginRequested/>, isLoggedIn)}
          {protectedRoute("/dashboard", <Dashboard/>, isLoggedIn)}
          {protectedRoute("/projects/create", <CreateProject/>, isLoggedIn)}
          {protectedRoute("/projects/:projectId", <ProjectDetails/>, isLoggedIn)}
          {protectedRoute("/configurations/basic", <BasicConfiguration/>, isLoggedIn)}
          {protectedRoute("/configurations/materials", <MaterialsConfiguration/>, isLoggedIn)}
          {protectedRoute("/configurations/power", <PowerConfiguration/>, isLoggedIn)}
          {protectedRoute("/configurations/blanket", <BlanketConfiguration/>, isLoggedIn)}
          {protectedRoute("/configurations/:configurationId", <ConfigurationDetails/>, isLoggedIn)}
        </Routes>
      )}
    </Layout>
  );
}

export default AppContent;