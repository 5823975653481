import {useState} from "react";
import {Button, Container, Description, Form, Input} from "../SharedStyles";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setAccessToken} from "../../../store/reducers/userSlice";
import ErrorMessage from "../../Shared/ErrorMessage";

const CreateUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { email, domain, orgName } = useSelector((state) => state.user);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const userData = { email, domain, orgName, password };

    try {
      const response = await fetch(API_URL + '/signup', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData)
      });

      const data = await response.json();

      if (!response.ok) {
        setError(data.error || 'Signup failed. Please try again.');
        return;
      }

      dispatch(setAccessToken(data.accessToken));
      navigate('/dashboard');
    } catch (err) {
      setError('Network error. Please try again.');
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Description>Finish your account setup.</Description>
        <Description>Please set your password: </Description>
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Description>Confirm your password: </Description>
        <Input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {error && <ErrorMessage message={error} />}
        <Button type="submit">Create</Button>
      </Form>
    </Container>
  );
};

export default CreateUser;