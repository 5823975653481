import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {clearCurrentProject} from "../../store/reducers/projectSlice";
import {Container, Nav, ProjectInfo, ProjectLink, Title} from "./styled";

const OrgBar = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const {email, orgName, domain} = useSelector((state) => state.user);
  const currentProject = useSelector((state) => state.project.currentProject);

  const isOrgSiteEnabled = process.env.REACT_APP_ORG_SITE_ENABLED === 'True';

  useEffect(() => {
    if (!location.pathname.startsWith('/projects/')
      && !location.pathname.startsWith('/configurations/')) {
      dispatch(clearCurrentProject());
    }
  }, [location, dispatch]);

  return (
    <Container>
      {isOrgSiteEnabled && (
        <Title>
          {orgName} - {domain}
        </Title>
      )}
      {!isOrgSiteEnabled && (
        <Title>
          {email}
        </Title>
      )}
      <Nav>
      </Nav>
      {currentProject && (
        <ProjectInfo>
          <ProjectLink href={'/projects/' + currentProject.projectId}>
            Project #{currentProject.projectId}
          </ProjectLink>
          : {currentProject.name}
        </ProjectInfo>
      )}
    </Container>
  );
}

export default OrgBar;