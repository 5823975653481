import styled from "styled-components";

export const ProjectLink = styled.a`
  margin-top: 10px;
  color: black;
  text-decoration: underline;
  justify-items: left;

  &:visited {
    color: black;
  }

  &:hover {
    color: #777;
  }
`;