import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
  setBlanketType,
  setFirstWallType,
  setPrimaryCoolantType,
  setSecondaryCoolantType,
  setNeutronMultiplier,
  setStructure
} from "../../../store/reducers/configurationSlice";
import {
  BackButton,
  Button,
  ButtonsWrapper,
  Container,
  Dropdown,
  DropdownContainer,
  Form,
  Label, LabelWrapper,
  Title
} from "../SharedStyles";
import { ValidationError } from "./styled";
import InfoTooltip from "../../Shared/InfoTooltip";


const BlanketConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [validationError, setValidationError] = useState(false);

  const firstWallType = useSelector(state => state.configuration.input.blanket.firstWallType);
  const blanketType = useSelector(state => state.configuration.input.blanket.blanketType);
  const primaryCoolantType = useSelector(state => state.configuration.input.blanket.primaryCoolantType);
  const secondaryCoolantType = useSelector(state => state.configuration.input.blanket.secondaryCoolantType);
  const neutronMultiplier = useSelector(state => state.configuration.input.blanket.neutronMultiplier);
  const structure = useSelector(state => state.configuration.input.blanket.structure);

  const isFormValid = () => {
    return firstWallType !== '' &&
      blanketType !== '' &&
      primaryCoolantType !== '' &&
      secondaryCoolantType !== '' &&
      neutronMultiplier !== '' &&
      structure !== '';
  };

  const handleBack = () => {
    navigate("/configurations/power")
  }

  const handleNext = () => {
    if (isFormValid()) {
      setValidationError(false)
      navigate("/configurations/materials")
    } else {
      setValidationError(true);
    }
  }

  function empty(value) {
    return value === '';
  }

  return (
    <Container>
      <Title>Blanket Configuration</Title>
      <Form>
        <DropdownContainer>
          <LabelWrapper>
            <Label htmlFor="firstWallType">First Wall Type:</Label>
            <InfoTooltip infoText="Material of the first wall." id='blanket-reactor-type-tooltip'/>
          </LabelWrapper>
          { empty(firstWallType) && validationError && <ValidationError>Please select a first wall type.</ValidationError> }
          <Dropdown
            id="firstWallType"
            value={firstWallType}
            onChange={(e) => dispatch(setFirstWallType(e.target.value))}
          >
            <option value="">Select a first wall type</option>
            <option value="TUNGSTEN">Tungsten</option>
            <option value="LIQUID_LITHIUM">Liquid Lithium</option>
            <option value="BERYLLIUM">Beryllium</option>
            <option value="FLIBE">FLiBe</option>
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <LabelWrapper>
            <Label htmlFor="blanketType">Blanket Type:</Label>
            <InfoTooltip infoText="Material of the blanket." id='blanket-material-type-tooltip'/>
          </LabelWrapper>
          { empty(blanketType) && validationError && <ValidationError>Please select a blanket type.</ValidationError> }
          <Dropdown
            id="blanketType"
            value={blanketType}
            onChange={(e) => dispatch(setBlanketType(e.target.value))}
          >
            <option value="">Select a blanket type</option>
            <option value="FLOWING_LIQUID_FIRST_WALL">Flowing Liquid First Wall</option>
            <option value="SOLID_FIRST_WALL_WITH_A_LIQUID_BREEDER">Solid First Wall with a Liquid Breeder</option>
            <option value="SOLID_FIRST_WALL_WITH_A_SOLID_BREEDER_LI4SIO4">Solid First Wall with Solid Breeder (Li4SiO4)</option>
            <option value="SOLID_FIRST_WALL_WITH_A_SOLID_BREEDER_LI2TIO3">Solid First Wall with Solid Breeder (Li2TiO3)</option>
            <option value="SOLID_FIRST_WALL_NO_BREEDER_ANEUTRONIC_FUEL">Solid First Wall No Breeder (Aneutronic Fuel)</option>
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <LabelWrapper>
            <Label htmlFor="primaryCoolantType">Primary Coolant Type:</Label>
            <InfoTooltip infoText="Material of the primary coolant." id='primary-coolant-material-tooltip'/>
          </LabelWrapper>
          { empty(primaryCoolantType) && validationError && <ValidationError>Please select a primary coolant type.</ValidationError> }
          <Dropdown
            id="primaryCoolantType"
            value={primaryCoolantType}
            onChange={(e) => dispatch(setPrimaryCoolantType(e.target.value))}
          >
            <option value="">Select a primary coolant type</option>
            <option value="LEAD_LITHIUM_PBLI">Lead Lithium (PbLi)</option>
            <option value="LITHIUM_LI">Lithium (Li)</option>
            <option value="FLIBE">FliBe</option>
            <option value="OTHER_EUTECTIC_SALT">Other Eutectic Salt</option>
            <option value="HELIUM">Helium (He)</option>
            <option value="DUAL_COOLANT_PBLI_AND_HE">Dual Coolant: PbLi and He</option>
            <option value="WATER">Water</option>
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <LabelWrapper>
            <Label htmlFor="secondaryCoolantType">Secondary Coolant Type:</Label>
            <InfoTooltip infoText="Material of the secondary coolant." id='secondary-coolant-material-tooltip'/>
          </LabelWrapper>
          { empty(secondaryCoolantType) && validationError && <ValidationError>Please select a secondary coolant type.</ValidationError> }
          <Dropdown
            id="secondaryCoolantType"
            value={secondaryCoolantType}
            onChange={(e) => dispatch(setSecondaryCoolantType(e.target.value))}
          >
            <option value="">Select a secondary coolant type</option>
            <option value="NONE">None</option>
            <option value="LEAD_LITHIUM_PBLI">Lead Lithium (PbLi)</option>
            <option value="LITHIUM_LI">Lithium (Li)</option>
            <option value="FLIBE">FliBe</option>
            <option value="OTHER_EUTECTIC_SALT">Other Eutectic Salt</option>
            <option value="HELIUM">Helium (He)</option>
            <option value="DUAL_COOLANT_PBLI_AND_HE">Dual Coolant: PbLi and He</option>
            <option value="WATER">Water</option>
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <LabelWrapper>
            <Label htmlFor="neutronMultiplier">Neutron Multiplier:</Label>
            <InfoTooltip infoText="Material for the neutron multiplication for tritium breeding in the blanket." id='neutron-multiplier-tooltip'/>
          </LabelWrapper>
          { empty(neutronMultiplier) && validationError && <ValidationError>Please select a neutron multiplier.</ValidationError> }
          <Dropdown
            id="neutronMultiplier"
            value={neutronMultiplier}
            onChange={(e) => dispatch(setNeutronMultiplier(e.target.value))}
          >
            <option value="">Select a neutron multiplier</option>
            <option value="NONE">None</option>
            <option value="BE">Beryllium (Be)</option>
            <option value="PB">Lead (Pb)</option>
            <option value="PB_AS_PART_OF_PBLI">Pb as part of PbLi</option>
            <option value="BE12TI">Beryllium Titanium (Be12Ti)</option>
          </Dropdown>
        </DropdownContainer>
        <DropdownContainer>
          <LabelWrapper>
            <Label htmlFor="structure">Structure:</Label>
            <InfoTooltip infoText="Structural material for the power core." id='structure-tooltip'/>
          </LabelWrapper>
          { empty(structure) && validationError && <ValidationError>Please select a structure.</ValidationError> }
          <Dropdown
            id="structure"
            value={structure}
            onChange={(e) => dispatch(setStructure(e.target.value))}
          >
            <option value="">Select a structure</option>
            <option value="STAINLESS_STEEL_SS">Stainless Steel (SS)</option>
            <option value="FERRITIC_MARTENSITIC_STEEL_FMS">Ferritic Martensitic Steel (FMS)</option>
            <option value="OXIDE_DISPERSION_STRENGTHENED_ODS_STEEL">Oxide Dispersion Strengthened Steel (ODS)</option>
            <option value="VANADIUM">Vanadium</option>
          </Dropdown>
        </DropdownContainer>
        <ButtonsWrapper>
          <BackButton type="button" onClick={handleBack}>Back</BackButton>
          <Button type="button" onClick={handleNext}>Next</Button>
        </ButtonsWrapper>
      </Form>
    </Container>
  )
};

export default BlanketConfiguration;