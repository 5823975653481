import {useDispatch, useSelector} from "react-redux";
import {
  setName,
  setFusionPower,
  setAM,
  setConstructionTime,
  setNumberModules,
  setPlantAvailabilityFactor,
  setPlantLifetime
} from "../../../store/reducers/configurationSlice";
import {Button, Container, Form, Title} from "../SharedStyles";
import SliderInput from "../../Shared/SliderInput";
import {useNavigate} from "react-router-dom";
import {Input, Label} from "../../Projects/CreateProject/styled";


const BasicConfiguration = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const name = useSelector(state => state.configuration.name);
  const initFusionPower = useSelector(state => state.configuration.input.basic.fusionPower);
  const initNumberModules = useSelector(state => state.configuration.input.basic.numberModules);
  const initAM = useSelector(state => state.configuration.input.basic.aM);
  const initConstructionTime = useSelector(state => state.configuration.input.basic.constructionTime);
  const initPlantLifetime = useSelector(state => state.configuration.input.basic.plantLifetime);
  const initPlantAvailabilityFactor = useSelector(state => state.configuration.input.basic.plantAvailabilityFactor);

  const handleNext = () => {
    navigate("/configurations/power")
  }

  return (
    <Container>
      <Title>Basic Configuration</Title>
      <Form>
        <Label>Configuration Name:</Label>
        <Input
          type="text"
          placeholder="Enter configuration name"
          value={name}
          onChange={(e) => dispatch(setName(e.target.value))}
        />
        <SliderInput
          label="Fusion power"
          description="Power generated by one module in the reactor (before the turbines)."
          unit="MW"
          defaultValue={2600}
          min={0}
          max={10000}
          step={10}
          setValue={(value) => dispatch(setFusionPower(value))}
          initValue={initFusionPower}
        />
        <SliderInput
          label="Number of Modules"
          description="The number of fusion cores."
          defaultValue={2}
          min={2}
          max={4}
          step={1}
          setValue={(value) => dispatch(setNumberModules(value))}
          initValue={initNumberModules}
        />
        <SliderInput
          label="AM"
          defaultValue={1}
          min={1}
          max={10}
          step={1}
          setValue={(value) => dispatch(setAM(value))}
          initValue={initAM}
        />
        <SliderInput
          label="Construction Time"
          description="The number of years to construct everything."
          unit="Years"
          defaultValue={3}
          min={1}
          max={10}
          step={1}
          setValue={(value) => dispatch(setConstructionTime(value))}
          initValue={initConstructionTime}
        />
        <SliderInput
          label="Plant lifetime"
          description="How long will the plant be operational?"
          unit="Years"
          defaultValue={30}
          min={10}
          max={100}
          step={1}
          setValue={(value) => dispatch(setPlantLifetime(value))}
          initValue={initPlantLifetime}
        />
        <SliderInput
          label="Plant availability factor"
          description="Percentage of time the plant is available."
          defaultValue={0.85}
          min={0}
          max={1}
          step={0.01}
          setValue={(value) => dispatch(setPlantAvailabilityFactor(value))}
          initValue={initPlantAvailabilityFactor}
        />
        <Button type="button" onClick={handleNext}>Next</Button>
      </Form>
    </Container>
  )
};

export default BasicConfiguration;