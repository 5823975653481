import {Button, Container, Description, Form} from "../SharedStyles";
import {Highlight} from "../CreateOrg/styled";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";


const LoginRequested = () => {
  const navigate = useNavigate();
  const orgDomain = useSelector((state) => state.user.domain);

  const handleRequest = (e) => {
    e.preventDefault();
    navigate('/')
  };

  return (
    <Container>
      <Form onSubmit={handleRequest}>
        <Description>You're login was successfully requested from the admin of <Highlight>@{orgDomain}</Highlight>.</Description>
        <Description>Please check your email for the request approval.</Description>
        <Button type="submit">Back to homepage</Button>
      </Form>
    </Container>
  );
};

export default LoginRequested;