import styled from 'styled-components';
import backgroundImage from '../../../assets/midjourney-fusion-future.png';

export const Container = styled.div`
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
  position: relative;
`;

export const Wrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 15px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  box-sizing: border-box;
`;

export const Slogan = styled.h1`
  color: #fff;
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem 0;
`;

export const ActionButton = styled.button`
  padding: 1rem 2rem;
  background-color: #0a72a4;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1.25rem;
  cursor: pointer;
  &:hover {
    background-color: #0c87c2;
  }
`;
