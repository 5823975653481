import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

const API_URL = process.env.REACT_APP_API_BASE_URL;
const isOrgSiteEnabled = process.env.REACT_APP_ORG_SITE_ENABLED === 'True';
const BASE_PATH = isOrgSiteEnabled ? 'org/projects' : 'user/projects';

const initialState = {
  error: '',
  configurations: [],
  currentProject: null,
  projects: [],
};

export const createProject = createAsyncThunk(
  'project/createProject',
  async (projectData, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;

    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(projectData),
      });

      const data = await response.json();
      if (!response.ok) {
        return thunkAPI.rejectWithValue(data.error || 'Create project failed. Please try again.');
      }

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const fetchProject = createAsyncThunk(
  'project/fetchProject',
  async (projectId, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;

    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}/${projectId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData.error || 'Project not found...');
      }

      return response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const fetchProjects = createAsyncThunk(
  'project/fetchProjects',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;

    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData.error || 'Projects not found...');
      }

      return response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

export const fetchConfigurations = createAsyncThunk(
  'configuration/fetchConfigurations',
  async (projectId, thunkAPI) => {
    const token = thunkAPI.getState().user.accessToken;

    try {
      const response = await fetch(`${API_URL}/${BASE_PATH}/${projectId}/configurations`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      if (!response.ok) {
        const errorData = await response.json();
        return thunkAPI.rejectWithValue(errorData.error || 'Configurations not found...');
      }

      return response.json();
    } catch (error) {
      return thunkAPI.rejectWithValue('Unexpected error. Please try again.');
    }
  }
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    clearCurrentProject: (state) => {
      state.currentProject = null;
    },
  },
  extraReducers: {
    [createProject.fulfilled]: (state, action) => {
      state.projects.push(action.payload); // Optionally update state with the new project
    },
    [createProject.rejected]: (state, action) => {
      state.error = action.payload || action.error.message;
    },
    [fetchProject.fulfilled]: (state, action) => {
      state.currentProject = action.payload;
    },
    [fetchProject.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchProjects.fulfilled]: (state, action) => {
      state.projects = action.payload;
    },
    [fetchProjects.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [fetchConfigurations.fulfilled]: (state, action) => {
      state.configurations = action.payload;
    },
    [fetchConfigurations.rejected]: (state, action) => {
      state.error = action.error.message;
    }
  },
});

export const { setCurrentProject, clearCurrentProject } = projectSlice.actions;
export default projectSlice.reducer;
