import React from 'react';
import Hero from './Hero';
import { Container } from './styled';

const Landing = () => (
  <Container>
    <Hero />
  </Container>
);

export default Landing;
