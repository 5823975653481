import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {
  Nav,
  CatfLogo,
  NavItem,
  Container,
  NavButton,
  WsltdLogo,
  NavSection,
  LeftSection,
  CatfItem
} from './styled';
import catfLogo from '../../assets/catf-logo.png';
import wsltdLogo from '../../assets/wsltd-logo.png';
import {logout} from "../../store/reducers/userSlice";

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <Container>
      <LeftSection>
        <Link to="https://www.catf.us/" target="_blank" rel="noopener noreferrer">
          <CatfLogo src={catfLogo} alt="Clean Air Task Force"/>
        </Link>
        <CatfItem as={Link} to="/">Fusion Costing Calculator</CatfItem>
      </LeftSection>
      <NavSection>
        <Nav>
          {/*<NavItem href="#products">Products & Services</NavItem>*/}
          {/*<NavItem href="#company">Company</NavItem>*/}
          <NavItem href="mailto:info@woodruffscientific.com">Contact Us</NavItem>
          {!isLoggedIn && <NavItem as={Link} to="/signup">Signup</NavItem>}
          {!isLoggedIn && <NavItem as={Link} to="/login">Login</NavItem>}
          {isLoggedIn && <NavItem as={Link} to="/dashboard">Dashboard</NavItem>}
          {isLoggedIn && <NavButton onClick={handleLogout} to="/logout">Logout</NavButton>}
        </Nav>
        <Link to="https://woodruffscientific.com/" target="_blank" rel="noopener noreferrer">
          <WsltdLogo src={wsltdLogo} alt="Woodruff Scientific Ltd"/>
        </Link>
      </NavSection>
    </Container>
  );
}

export default NavBar;
