import {useDispatch, useSelector} from "react-redux";
import {
  createConfiguration,
  setCopperCost,
  setMfrFactor,
  setStainlessSteelCost,
  setYbcoTapeCost
} from "../../../store/reducers/configurationSlice";
import {AdvancedSettings, BackButton, Button, ButtonsWrapper, Container, Form, Title} from "../SharedStyles";
import SliderInput from "../../Shared/SliderInput";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import AdvancedSettingsButton from "../../Shared/AdvancedSettingsoButton";
import ErrorMessage from "../../Shared/ErrorMessage";



const MaterialsConfiguration = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const initYbcoTapeCost = useSelector(state => state.configuration.input.materials.ybcoTapeCost);
  const initStainlessSteelCost = useSelector(state => state.configuration.input.materials.stainlessSteelCost);
  const initCopperCost = useSelector(state => state.configuration.input.materials.copperCost);
  const initMfrFactor = useSelector(state => state.configuration.input.materials.mfrFactor);

  const toggleAdvanced = () => setShowAdvanced(!showAdvanced);

  const handleBack = () => {
    navigate("/configurations/blanket")
  }

  const handleCreate = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const configurationId = await dispatch(createConfiguration()).unwrap();
      navigate("/configurations/" + configurationId);
    } catch (error) {
      setError('Unexpected error. Please try again.');
      console.log(error);
    }
  };

  return (
    <Container>
      <Title>Materials Configuration</Title>
      <Form onSubmit={handleCreate}>
        <AdvancedSettingsButton
          showAdvanced={showAdvanced}
          onToggle={toggleAdvanced}
        />
        <AdvancedSettings isVisible={showAdvanced}>
          <SliderInput
            label="Cost of YBCO tape"
            unit="$/kAm"
            defaultValue={80}
            min={1}
            max={200}
            step={1}
            setValue={(value) => dispatch(setYbcoTapeCost(value))}
            initValue={initYbcoTapeCost}
          />
          <SliderInput
            label="Cost of Stainless Steel"
            unit="$/kg"
            defaultValue={5}
            min={1}
            max={20}
            step={0.1}
            setValue={(value) => dispatch(setStainlessSteelCost(value))}
            initValue={initStainlessSteelCost}
          />
          <SliderInput
            label="Cost of Copper"
            unit="$/kg"
            defaultValue={10.3}
            min={1}
            max={20}
            step={0.1}
            setValue={(value) => dispatch(setCopperCost(value))}
            initValue={initCopperCost}
          />
          <SliderInput
            label="MFR Factor"
            defaultValue={3}
            min={1}
            max={10}
            step={1}
            setValue={(value) => dispatch(setMfrFactor(value))}
            initValue={initMfrFactor}
          />
        </AdvancedSettings>
        <ButtonsWrapper>
          <BackButton type="button" onClick={handleBack}>Back</BackButton>
          <Button type="submit">Create</Button>
        </ButtonsWrapper>
        {error && <ErrorMessage message={error} />}
      </Form>
    </Container>
  )
};

export default MaterialsConfiguration;