
export function removeNullValues(obj, exclusions = []) {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeNullValues(obj[key]); // Recurse into nested objects
    } else if (obj[key] === null && !exclusions.includes(key)) {
      delete obj[key]; // Delete keys with null values that are not in the exclusions list
    }
  });
  return obj;
}