import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  max-width: 1000px;
  margin: auto;
`;

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2vh;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 500px;
  border: 2px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0 20px 20px 20px;
`;


export const Loading = styled(Container)`
`;

export const NotFound = styled(Container)`
`;

export const Title = styled.h3`
  font-size: 20px;
  color: #333;
  margin-bottom: 0;
`;

export const Description = styled.p`
  font-size: 14px;
  color: #333;
  margin-bottom: 0;
`;

export const Highlight = styled.span`
  font-weight: bold;
`;

export const Button = styled.button`
  margin-top: 20px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

export const ConfigurationList = styled.ul`
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 20px;
`;

export const ConfigurationItem = styled.li`
  margin-top: 10px;
  font-size: 14px;
  color: #333;
`;

export const ConfigurationLink = styled.a`
  margin-top: 5px;
  color: black;
  text-decoration: underline;

  &:visited {
    color: black;
  }

  &:hover {
    color: #777;
  }
`;
