import styled from 'styled-components';

export const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const LabelWrapper = styled.label`
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
`;

export const ValueInput = styled.input`
  width: 60px;
  margin-left: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
`;

export const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #ddd;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 5px;
  margin-top: 5px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #007bff;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #007bff;
    cursor: pointer;
    border-radius: 50%;
  }
`;

export const SliderWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 5px;
`;

export const MinValue = styled.span`
  margin-right: 10px;
  width: 30px;
  text-align: right;
`;

export const MaxValue = styled.span`
  margin-left: 10px;
  width: 30px;
`;
