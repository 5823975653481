import styled from "styled-components";

export const SignupWrapper = styled.div`
  margin-top: 10px;
`;

export const SignUpLink = styled.a`
  color: #333;

  &:hover {
    color: #777;
  }
`;
