import React, { useEffect, useState } from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {fetchConfigurations, fetchProject} from '../../../store/reducers/projectSlice';
import {clearConfiguration, setProjectId} from "../../../store/reducers/configurationSlice";
import ErrorMessage from "../../Shared/ErrorMessage";
import {
  Button,
  ConfigurationItem,
  ConfigurationLink, ConfigurationList,
  Container,
  Description,
  FlexContainer,
  Highlight,
  Loading,
  NotFound,
  Title
} from "./styled";
import {displayFuelType, displayReactorType, firstUpper} from "../../../utils/viewUtils";

const ProjectDetails = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const project = useSelector(state => state.project.currentProject);
  const configurations = useSelector(state => state.project.configurations);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    dispatch(fetchProject(projectId))
      .unwrap()
      .then(() => {
        dispatch(fetchConfigurations(projectId));
      })
      .catch(error => {
        console.error('Error:', error);
        setError(error);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [projectId, dispatch]);

  const onErrorClick = () => {
    navigate("/projects/create")
  }

  const onCreate = () => {
    dispatch(clearConfiguration())
    dispatch(setProjectId(Number(projectId)))
    navigate("/configurations/basic")
  }

  if (loading) return (
    <Loading>Loading...</Loading>
  );

  if (error) return (
    <NotFound>
      {error && <ErrorMessage message={error} />}
      <Button onClick={onErrorClick}>Create new project</Button>
    </NotFound>
  );

  return (
    <FlexContainer>
      <Container>
        <Title>Project #{project.projectId}: {project.name}</Title>
        <Description>Reactor Type: <Highlight>{displayReactorType(project.reactorType)}</Highlight></Description>
        <Description>Energy Conversion: <Highlight>{firstUpper(project.energyConversion)}</Highlight></Description>
        <Description>Fuel Type: <Highlight>{displayFuelType(project.fuelType)}</Highlight></Description>
      </Container>
      <Container>
        <Title>Configurations</Title>
        <ConfigurationList>
        {configurations.map(configuration => (
          <ConfigurationItem key={configuration.configurationId}>
            <ConfigurationLink href={`/configurations/${configuration.configurationId}`}>
              Configuration #{configuration.projectConfigurationId}
            </ConfigurationLink>
            : {configuration.name}
          </ConfigurationItem>
        ))}
        </ConfigurationList>
        <Button onClick={onCreate}>Create</Button>
      </Container>
    </FlexContainer>
  );
};

export default ProjectDetails;
