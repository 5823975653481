import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_API_BASE_URL

const initialState = {
  email: '',
  domain: '',
  orgName: '',
  isLoggedIn: false,
};

export const getUserState = createAsyncThunk(
  'getUserState',
  async (args, { getState }) => {
    let API_ENDPOINT = API_URL + `/user`;

    try {
      const token = localStorage.getItem('accessToken');

      if (!token) {
        return initialState;
      }

      const response = await fetch(API_ENDPOINT, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return await response.json();
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setDomain: (state, action) => {
      state.domain = action.payload;
    },
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    checkLoginStatus: (state) => {
      const token = localStorage.getItem('accessToken');

      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        console.log(decodedToken);

        if (decodedToken.exp >= currentTimestamp) {
          state.isLoggedIn = true;
          state.accessToken = token;
        } else {
          localStorage.removeItem('accessToken');
          state.isLoggedIn = false;
          state.accessToken = '';
        }
      }
    },
    setAccessToken: (state, action) => {
      const token = action.payload;
      if (token) {
        const decodedToken = jwtDecode(token);
        console.log(decodedToken);
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp >= currentTimestamp) {
          console.log("token is valid");
          localStorage.setItem('accessToken', token);
          state.isLoggedIn = true;
          state.accessToken = token
        }
        console.log("token is not valid");
      }
    },
    logout: () => {
      localStorage.removeItem('accessToken');
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserState.fulfilled, (state, action) => {
        state.email = action?.payload?.email;
        state.domain = action?.payload?.domain;
        state.orgName = action?.payload?.orgName;
      });
  },
});

export const {
  setEmail,
  setDomain,
  setOrgName ,
  checkLoginStatus,
  setAccessToken,
  logout,
} = userSlice.actions;

export default userSlice.reducer;